import React from "react"
import { Image } from "@chakra-ui/image"
import { AspectRatio } from "@chakra-ui/layout"

import Blog from "~images/layout/header/resources/blog.svg"
import Network from "~images/layout/header/resources/network.svg"
import Compare from "~images/layout/header/resources/compare.svg"

export type Resource = {
    label: string
    href: string
    description: string
    flair: React.ReactNode
}

export const resources: Resource[] = [
    {
        label: "Compare",
        href: "/compare",
        description: "See how we stack up against your current provider",
        flair: <ResourceFlair src={Compare} alt="Compare" />,
    },
    {
        label: "Partners & Consultants",
        href: "/partners",
        description: "Our partners in the industry",
        flair: <ResourceFlair src={Network} alt="Our Consultants" />,
    },
    {
        label: "Blog",
        href: "/blog",
        description: "Read our latest articles",
        flair: <ResourceFlair src={Blog} alt="Blog" />,
    },
]

export function ResourceFlair({ src, alt }: { src: string; alt: string }) {
    return (
        <AspectRatio ratio={1} width={10}>
            <Image src={src} alt={alt} w="full" h="full" />
        </AspectRatio>
    )
}
