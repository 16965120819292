import { FaXTwitter } from "react-icons/fa6"
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa"
import { LinkType } from "./link"

export const About: LinkType[] = [
    {
        name: "Blog",
        href: "/blog",
    },
    {
        name: "Terms and Conditions",
        href: "/terms-and-conditions",
    },
    {
        name: "Company",
        href: "/company",
    },
    {
        name: "Partners & Consultants",
        href: "/partners",
    },
    {
        name: "Become a Partner",
        href: "/partner-form",
    },
    {
        name: "Privacy Policy",
        href: "/privacy-policy",
    },
]

export const Compare: LinkType[] = [
    {
        name: "AllyDVM",
        href: "/compare/allydvm",
    },
    {
        name: "Demandforce",
        href: "/compare/demandforce",
    },
    {
        name: "Next In Line",
        href: "/compare/next-in-line",
    },
    {
        name: "Otto",
        href: "/compare/otto",
    },
    {
        name: "PetDesk",
        href: "/compare/petdesk",
    },
    {
        name: "Petly",
        href: "/compare/petly",
    },
    {
        name: "PetPro Connect",
        href: "/compare/petpro-connect",
    },
    {
        name: "PetsApp",
        href: "/compare/petsapp",
    },
    {
        name: "Rapport",
        href: "/compare/rapport",
    },
    {
        name: "Vello",
        href: "/compare/vello",
    },
    {
        name: "Vet2Pet",
        href: "/compare/vet2pet",
    },
    {
        name: "Vetstoria",
        href: "/compare/vetstoria",
    },
    {
        name: "VIN Practice Communications",
        href: "/compare/vin-practice-communications",
    },
    {
        name: "VitusVet",
        href: "/compare/vitusvet",
    },
    {
        name: "Weave",
        href: "/compare/weave",
    },
]

export const Socials: LinkType[] = [
    {
        name: "LinkedIn",
        href: "https://www.linkedin.com/company/chckvet",
        icon: FaLinkedin,
        isExternal: true,
    },
    {
        name: "Facebook",
        href: "https://www.facebook.com/chckvet",
        icon: FaFacebook,
        isExternal: true,
    },
    {
        name: "X / Twitter",
        href: "https://www.twitter.com/chckvet",
        icon: FaXTwitter,
        isExternal: true,
    },
    {
        name: "Instagram",
        href: "https://www.instagram.com/chckvet",
        icon: FaInstagram,
        isExternal: true,
    },
]
